<template>
	<div class="container">
		<div class="section">
			<div class="row">
				<div
					class="col-12 col-md-4 col-lg-3 d-flex justify-content-center align-items-center"
				>
					<div class="header-image"></div>
				</div>
				<div class="col-12 col-md-8 col-lg-9">
					<div class="row">
						<div class="col-12"><small>基本資料</small></div>
					</div>
					<hr class="mt-1 mb-1" />
					<div class="row line">
						<div class="col-12 d-flex align-items-center">
							<h4 class="me-2 mb-0"><strong>高子殷</strong></h4>
							<h6 class="mb-0">
								<span class="me-1 badge rounded-pill bg-primary">專案開發</span>
								<span class="me-1 badge rounded-pill bg-primary">需求整合</span>
								<span class="me-1 badge rounded-pill bg-primary"
									>跨部門溝通</span
								>
								<span class="me-1 badge rounded-pill bg-primary">技術導入</span>
							</h6>
						</div>
					</div>
					<hr class="hr-header" />
					<div class="row">
						<div class="col-12">全端開發工程師 | 12年開發經驗</div>
					</div>
					<hr class="hr-header" />
					<div class="row">
						<div class="col-12">
							<ul class="m-0">
								<li>12年以上專案管理與開發經驗，善於整合使用者潛在需求</li>
								<li>抗壓性高、擅團隊領導、溝通協調、需求整合、功能分析</li>
								<li>可驅動團隊開發、技術導入，亦可獨立作業</li>
								<li>
									導入 Git 版控、CI/CD 與 自動發布工具，加速開發流程簡化環境佈署
								</li>
								<li>善於使用 C#、Vue 進行專案開發</li>
								<li>業餘時自我精進，喜歡研究各類前端、後端開發工具</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<hr class="hr-header" />
		</div>
		<!-- <div class="section">
			<div class="row">
				<div class="col-12"><small>學歷</small></div>
			</div>
			<hr class="mt-1 mb-1" />
			<div class="row">
				<div class="col-12">
					<div class="row line">
						<div class="col-2 text-end">學校</div>
						<div class="col-4">崑山科技大學</div>
						<div class="col-2 text-end">系所</div>
						<div class="col-4">高分子材料系</div>
					</div>
					<hr class="hr" />
					<div class="row line">
						<div class="col-2 text-end">學歷</div>
						<div class="col-4">大學</div>
						<div class="col-2 text-end">就學期間</div>
						<div class="col-4">2007年9月 至 2011年7月</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="section">
			<div class="row">
				<div class="col-12"><small>專長</small></div>
			</div>
			<hr class="mt-1 mb-1" />
			<div class="row">
				<div class="col-12 col-md-4 mb-2 border-end">
					<div class="row">
						<div class="col-12"><h5 class="mb-0">Web Design</h5></div>
					</div>
					<hr class="hr" />
					<div class="row">
						<div class="col-12">
							手刻實作各類 Layout。
							<ul class="m-0">
								<li>熟悉 HTML5、CSS3</li>
								<li>擅長 UI設計、UX設計、頁面配色</li>
								<li>熟悉 網頁版型切版</li>
								<li>熟悉 RWD響應式網頁設計</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-2 border-end">
					<div class="row">
						<div class="col-12"><h5 class="mb-0">Front End</h5></div>
					</div>
					<hr class="hr" />
					<div class="row">
						<div class="col-12">
							前端框架使用、事件處理、單頁網站、PWA應用 到 後端 WebAPI
							串接、資料接收。
							<ul class="m-0">
								<li>JavaScript ES5、ES6 / <strong>TypeScript</strong></li>
								<li>
									<strong>Vue 2 (Vue-CLI)</strong> /
									<strong>Vue 3 (Vite)</strong>
								</li>
								<li>
									<strong>React 18 (Remix)</strong>
								</li>
								<li>JQuery / BootStrap</li>
								<li><strong>PWA Application</strong> / SPA Page</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-2">
					<div class="row">
						<div class="col-12"><h5 class="mb-0">Back End</h5></div>
					</div>
					<hr class="hr" />
					<div class="row">
						<div class="col-12">
							後端 WebAPI 開發、DataBase 欄位設計規劃、商業邏輯編寫。
							<ul class="m-0">
								<li>
									<strong>.NET 5, 6, 7, 8</strong> / .NET Core 2.0, 3.0 / .NET
									Framework 4, 4.5, 4.8
								</li>
								<li>
									<strong>ASP.NET Core</strong> / <strong>ASP.NET MVC</strong> /
									ASP.NET WebForm
								</li>
								<li><strong>WebAPI</strong> / WebService / RESTful API</li>
								<li>
									<strong>SQL Server</strong> / MySQL / MariaDB /
									<strong>Oracle DB</strong>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<hr class="hr" />
			<div class="row">
				<div class="col-12 col-md-4 mb-2 border-end">
					<div class="row">
						<div class="col-12"><h5 class="mb-0">Server / Cloud</h5></div>
					</div>
					<hr class="hr" />
					<div class="row">
						<div class="col-12">
							使用自建伺服器架構 或 使用 Azure、AWS、GCP
							等雲服務建立可擴展服務。
							<ul class="m-0">
								<li>
									Self Host - <strong>Nginx</strong> / Ubuntu Server / Debian /
									<strong>Kubernetes</strong> /
									<strong>Harbor</strong>
								</li>
								<li>
									Azure - Virtual Machine /
									<strong>Container Instances</strong> / Container Registry
								</li>
								<li>AWS - EC2 / <strong>ECS</strong> / ECR</li>
								<li>
									GCP - Compute Engine / <strong>Kubernetes Engine</strong> /
									<strong>Container Registry</strong>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-2 border-end">
					<div class="row">
						<div class="col-12"><h5 class="mb-0">Version Control</h5></div>
					</div>
					<hr class="hr" />
					<div class="row">
						<div class="col-12">
							版本控制、原始碼管理、自動化佈署。
							<ul class="m-0">
								<li><strong>Gitlab</strong> / Github</li>
								<li>
									<strong>SourceTree</strong> / TortoiseGit / Git Extensions
								</li>
								<li><strong>CI/CD pipelines</strong> / Github Action / Jobs</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 mb-2">
					<div class="row">
						<div class="col-12"><h5 class="mb-0">Client App / Tools</h5></div>
					</div>
					<hr class="hr" />
					<div class="row">
						<div class="col-12">
							客戶端應用開發以及開發工具使用。
							<ul class="m-0">
								<li>WPF / WinForm</li>
								<li><strong>Docker</strong> / Docker Compose</li>
								<li><strong>ESLint</strong> / <strong>Prettier</strong></li>
								<li>NPM / WebPack / Vite</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<hr class="hr-header" />
		</div>
		<div class="section">
			<div class="row">
				<div class="col-12"><small>工作經驗</small></div>
			</div>
			<hr class="mt-1 mb-1" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">前進國際</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>在職起訖</small><br />2024年8月 至今 在職
						</div>
						<div class="col-4 border-end">
							<small>職稱</small><br />資深開發工程師
						</div>
						<div class="col-4"><small>駐廠</small><br />航空公司</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					駐廠於位於桃園的航空公司，基於 ASP.NET Core 與 React 採用 DDD 結構開發
					購票後台系統、旅客問卷系統後台、內部分析報表
					等等用於內部管理與稽核之系統與報表，供給公司內其他部門之管理人員進行查核與檢視。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>負責工作：SD / PG</li>
						<li>使用技術：.NET 6 / ASP.NET Core / WebAPI / React / MUI</li>
						<li>資料庫：OracleDB</li>
						<li>版本控制：Git / Azure DevOps</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">前進國際</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>在職起訖</small><br />2023年6月 至 2024年7月
						</div>
						<div class="col-4 border-end">
							<small>職稱</small><br />資深開發工程師
						</div>
						<div class="col-4"><small>駐廠</small><br />台南科學園區電子廠</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					駐廠於台南科學園區電子廠，基於 ASP.NET Core 建立
					廠務報表、稽核結算、各機台指標報表
					等等用於內部管理之報表，供給各廠廠端管理人員進行查核與檢視。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>負責工作：SA / PG</li>
						<li>
							使用技術：.NET 6 / ASP.NET Core / WebAPI / JQuery / VueJS / EChart
							/ DevExpress
						</li>
						<li>資料庫：SQL Server / OracleDB</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：GCP / 企業內部雲</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">前進國際</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>在職起訖</small><br />2019年3月 至 2023年5月
						</div>
						<div class="col-4 border-end">
							<small>職稱</small><br />資深開發工程師
						</div>
						<div class="col-4"><small>駐廠</small><br />台南工業園區電子廠</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					駐廠於台南科技工業園區光電廠，基於 MVC
					開發企業內部使用之整合式付款系統，後續進行新功能
					QRCode、手機憑證上傳、會計可調資料界面、費用不百檢 等功能開發。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>負責工作：PM / SA / PG</li>
						<li>
							使用技術：.NET Framework 4.5.2 / ASP.NET MVC / WebAPI / JQuery /
							VueJS
						</li>
						<li>資料庫：SQL Server / OracleDB</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">資勇企業</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>在職起訖</small><br />2018年10月 至 2019年3月
						</div>
						<div class="col-4"><small>職稱</small><br />開發工程師</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					於資勇企業進行資訊課基礎架構建設，協助資勇企業進行資訊化，建立虛擬化伺服器、版本控制伺服器、內部開發流程。期間開發
					資勇統一登入平台(基於
					AD)、品保品質回報系統、報表自動發送系統、倉管盤點系統。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>負責工作：PM / SA / PG / Infra</li>
						<li>
							使用技術：.NET Core 2.0 / .NET Framework 4.5.2 / ASP.NET Core /
							ASP.NET MVC / JQuery / Vue
						</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：Exsi / Windows Server / Ubuntu Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">萬通人力集團</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>在職起訖</small><br />2016年1月 至 2018年10月
						</div>
						<div class="col-4"><small>職稱</small><br />軟體工程師</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					開發並維護萬通人力 HR
					管理系統，該系統是萬通人力內部不僅用於管理本部員工
					計薪、考勤、請假、排班、勞健保統計，同時也用於派遣員工的計薪、勞健保功能，除桌上型電腦版本，也同時開發可於手機進行
					請假、簽核、查看班表、打卡 等功能的行動版本。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>負責工作：PM / SA / PG</li>
						<li>
							使用技術：.NET Framework 4.0 / ASP.NET MVC / ASP.NET WebForm /
							JQuery
						</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">工作室</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>在職起訖</small><br />2011年4月 至 2015年12月
						</div>
						<div class="col-4">
							<small>職稱</small><br />後端工程師、資料庫工程師
						</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					於工作室擔任後端工程師、資料庫工程師，工作室開發項目以協助企業做電子化管理為主要開案項目，包含
					倉儲管理系統、員工考勤管理系統、報表生成系統、品管系統 等項目。
					<hr class="hr" />
				</div>
			</div>
			<hr class="hr-header" />
		</div>
		<div class="section">
			<div class="row">
				<div class="col-12"><small>參與專案</small></div>
			</div>
			<hr class="mt-1 mb-1" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">航空公司 / 客戶服務管理系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2024年8月 至今
						</div>
						<div class="col-4"><small>來源</small><br />駐廠</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					桃園航空公司內部使用之客戶服務管理系統，可用於查詢與管理
					票務交易狀態、未出席數量、旅客問卷、綜合資訊分析 等等各系統之狀態。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：SD / PG</li>
						<li>使用技術：.NET 6 / ASP.NET Core / React / MUI / WebAPI</li>
						<li>資料庫：OracleDB</li>
						<li>版本控制：Git / Azure DevOps</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">南科電子廠 / 廠務管理系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2023年6月 至今
						</div>
						<div class="col-4"><small>來源</small><br />駐廠</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					南科電子廠內部使用之廠務資訊管理系統，可檢視各廠別機台狀態資訊、各類意外、降壓資訊顯示，依據各廠別、部門、產線調整上下限與加權數值。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：SA / PG</li>
						<li>
							使用技術：.NET 6 / ASP.NET Core / JQuery / VueJS / WebAPI / EChart
							/ DevExpress
						</li>
						<li>資料庫：SQL Server / OracleDB</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：企業內部雲 (Kubernetes)</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">南科電子廠 / 廠務報表系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2023年6月 至今
						</div>
						<div class="col-4"><small>來源</small><br />駐廠</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					南科電子廠內部使用之管理報表，依據機台資訊、廠內監控儀器，可實時顯示各指標數值狀態，生成可視化圖表、匯出數值報告。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：SA / PG</li>
						<li>
							使用技術：.NET 6 / ASP.NET Core / JQuery / VueJS / WebAPI / EChart
							/ DevExpress
						</li>
						<li>資料庫：SQL Server / OracleDB</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：企業內部雲 (Kubernetes)</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">南科工區電子廠 / 整合式請款系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2019年3月 至 2023年6月
						</div>
						<div class="col-4"><small>來源</small><br />駐廠</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					企業內部使用之請款系統，適用於廠商請款、員工請款、差旅請款，依據請款流程搭配企業內部簽呈系統進行請款，可用於電腦、平板、手機請款，除標準請款流程以外，另外加入
					紙本 QRCode 掃描功能、OCR 發票識別判斷、費用檢核功能、自動簽核功能。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>
							使用技術：.NET Framework 4.5.2 / ASP.NET MVC / JQuery / VueJS /
							WebAPI / SQL Server / OracleDB
						</li>
						<li>資料庫：SQL Server / OracleDB</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">資勇企業 / 資訊開發環境建立</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2018年10月 至 2019年3月
						</div>
						<div class="col-4"><small>來源</small><br />正職</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					協助資勇企業進行資訊開發環境架設，作為資勇企業未來資訊相關系統之開發環境使用，建立虛擬化伺服器、版本控制，並調整原實體伺服器、共用繪圖機到虛擬環境。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：Team Leader</li>
						<li>架設系統：Exsi Server、GPU Passthrough</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">資勇企業 / 統一登入平台</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2018年10月 至 2019年3月
						</div>
						<div class="col-4"><small>來源</small><br />正職</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					基於資勇企業內的 Active Directory
					開發統一登入界面，作為資勇企業內部其他系統可接入的登入前台。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>
							使用技術：.NET Core 2.0 / .NET Framework 4.5.2 / ASP.NET Core /
							ASP.NET MVC / ASP.NET WebForm / JQuery / WebAPI / Web Service
						</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">資勇企業 / 倉管盤點系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2018年10月 至 2019年3月
						</div>
						<div class="col-4"><small>來源</small><br />正職</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					資勇企業內部使用之倉儲盤點系統，作為年度盤點使用，以 ERP
					資料為基礎生成盤點標籤，員工使用手機掃描 QRCode
					進行實際品項確認，最終生成所需報表。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>
							使用技術：.NET Framework 4.5.2 / ASP.NET MVC / JQuery / VueJS /
							BootStrap / WebAPI
						</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">資勇企業 / 品保品質回報系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2018年1月 至 2019年3月
						</div>
						<div class="col-4"><small>來源</small><br />外包</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					資勇企業內部品管部門使用之管理系統，由品管人員將各類生產線上的異常項目上傳到系統，並依據各異常回報處理方式、處理結果，各類異常可透過查詢界面查找、檢視。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>
							使用技術：.NET Framework 4.5.2 / ASP.NET MVC / JQuery / BootStrap
							/ WebAPI
						</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">資勇企業 / 藥劑管理系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2018年1月 至 2018年4月
						</div>
						<div class="col-4"><small>來源</small><br />外包</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					資勇企業委託開發之內部藥劑用量管理，依據每日的預計藥劑、生產量進行計算與回報，並生成報表讓該公司相關部門主管能了解各個藥劑的預計量與實際使用量。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>
							使用技術：.NET Framework 4.7.2 / ASP.NET WebForm / JQuery /
							BootStrap / WebAPI
						</li>
						<li>資料庫：SQL Lite</li>
						<li>版本控制：Git</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">萬通人力 / 人力資源管理系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2016年1月 至 2018年9月
						</div>
						<div class="col-4"><small>來源</small><br />正職</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					萬通人力集團內部 人力派遣、正職員工
					使用之考勤、保險、計薪管理系統，包含考勤打卡、請假申請、保險規費計算、薪資計算、部門管理
					等功能。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>使用技術：.NET Framework 4.0 / ASP.NET WebForm / JQuery</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">萬通人力 / 宿舍點名管理系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2016年1月 至 2018年9月
						</div>
						<div class="col-4"><small>來源</small><br />正職</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					萬通人力內部管理外勞宿舍進出入、點名之系統，透過指紋識別系統識別各外勞的進出入，支援多宿舍、多班表管理，讓宿舍管理人員能便於管理外勞進出入。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>
							使用技術：.NET Framework 4.0 / ASP.NET MVC / JQuery / BootStrap
						</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">萬通人力 / 人力資源管理系統行動版</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2016年1月 至 2018年9月
						</div>
						<div class="col-4"><small>來源</small><br />正職</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					針對萬通人力集團正職員工設計的考勤打卡、請假申請系統的行動版本，針對手機、平板進行界面優化，可以在手機上進行打卡、請假、簽核等動作。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>使用技術：.NET Framework 4.0 / ASP.NET WebForm / JQuery</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">三建資訊 / 課程會員管理系統</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2019年1月 至 2021年6月
						</div>
						<div class="col-4"><small>來源</small><br />外包</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					三建資訊發包開發的會員管理系統，具備會員管理功能、課程管理功能、講師管理功能、電子報發送管理、課程簽到管理
					等等功能，作為該公司的公開課程管理使用之系統。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>
							使用技術：.NET Framework 4.7.2 / ASP.NET MVC / JQuery / Vue 2.0 /
							BootStrap / WebAPI
						</li>
						<li>資料庫：SQL Server</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：Windows Server</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
			<div class="row line">
				<div class="col-12">
					<h5 class="mb-0">私人 / 網路流量紀錄工具</h5>
				</div>
				<div class="col-12">
					<hr class="hr" />
					<div class="row">
						<div class="col-4 border-end">
							<small>專案起訖</small><br />2022年8月 至今
						</div>
						<div class="col-4"><small>來源</small><br />私人</div>
					</div>
					<hr class="hr" />
				</div>
				<div class="col-12">
					一款可以在桌面上顯示網路上下載速度，可依據不同網路、網卡進行紀錄的小工具，界面基於
					Windows 11 Fluent 製作，可於主界面中顯示流量紀錄。
					<hr class="hr" />
				</div>
				<div class="col-12">
					<ul class="m-0">
						<li>擔任角色：PM / SA / PG</li>
						<li>使用技術：.NET Framework 4.8 / WPF</li>
						<li>資料庫：SQL Lite</li>
						<li>版本控制：Git / Gitlab</li>
						<li>運行平台：Windows Client</li>
					</ul>
				</div>
			</div>
			<hr class="my-4" />
		</div>
		<!-- <div class="section">
			<div class="row">
				<div class="col-12"><small>參與專案</small></div>
			</div>
			<hr class="mt-1 mb-1" />
			<div class="row">
				<div class="col-12">
					<div class="row line">
						<div class="col-12">
							<strong>小艾版瑪奇補丁 Ver7</strong>
						</div>
						<div class="col-2 text-end">開發、維護</div>
						<div class="col-10">2022年12月 至今(未 Release)</div>
						<div class="col-2 text-end">相關技術</div>
						<div class="col-10">
							.NET 6、.NET Framework 4.8、Vue-CLI、WebAPI、Rust、SQL Server
						</div>
						<div class="col-2 text-end">工作內容</div>
						<div class="col-10">
							用於線上遊戲瑪奇的遊戲補丁，伺服端能自官方下載檔案並分析檔案，於分析之後生成修改過的文件。客戶端部份基於舊版補丁使用人數因此開發了新的統一登入界面。此項目目前已完成統一登入功能、官方檔案下載、分析功能。
						</div>
					</div>
					<hr class="hr" />
					<div class="row line">
						<div class="col-12">
							<strong>小艾版樂豆起動器</strong>
						</div>
						<div class="col-2 text-end">開發、維護</div>
						<div class="col-10">2016年5月 至 2021年5月</div>
						<div class="col-2 text-end">相關技術</div>
						<div class="col-10">
							.NET Framework 4.0、WPF、Charles、HTTP Fake
						</div>
						<div class="col-2 text-end">工作內容</div>
						<div class="col-10">
							逆向 遊戲橘子 Beanfun 樂豆
							網頁的一個登入工具，讓使用者能在不用打該官方網頁的情況下進行登入遊戲。使用者帳號與密碼透過機器唯一數值進行加密，除標準帳號密碼登入以外也支援遊戲橘子的雙驗證登入流程。
						</div>
					</div>
					<hr class="hr" />
					<div class="row line">
						<div class="col-12">
							<strong>小艾版瑪奇補丁 Ver1 ~ Ver6</strong>
						</div>
						<div class="col-2 text-end">開發、維護</div>
						<div class="col-10">2011年 至 2021年5月</div>
						<div class="col-2 text-end">相關技術</div>
						<div class="col-10">
							.NET Framework 4.0、WinForm -> WPF、WebForm -> MVC、SignalR、Web
							API、Private API
						</div>
						<div class="col-2 text-end">工作內容</div>
						<div class="col-10">
							用於線上遊戲瑪奇的遊戲補丁，於 Ver3 以前基於 WinForm
							製作使用者界面，於 Ver3 開始基於 WPF
							製作使用者界面，伺服器初期基於 ASP.NET WebForm 於 Ver5 之後轉為
							MVC。整套系統由
							使用者維護工具、染色工具、自動更新系統、伺服器管理工具、伺服器推送更新系統
							等等的程式組成。使用者於安裝之後可免於手動更新、維護，伺服器能自動推送新版本到使用者的電腦上。此系統於結束維護前約有六千個使用者。
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="row my-4">
			<div class="col-12 text-center">最後更新於 2024年10月16日</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MainComponent",
	props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.section {
	width: 100%;
	border-radius: 1rem;
	border: 1px solid #ccc;
	box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
	padding: 0.5rem 1rem 0.5rem 1rem;
	margin-bottom: 1.5rem;
}
.line {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
.hr {
	border-top: 1px solid #aaa;
	margin-bottom: 0.25rem;
	margin-top: 0.25rem;
}
.hr-header {
	border-top: 1px solid rgba(0, 0, 0, 0);
	margin-bottom: 0.25rem;
	margin-top: 0.25rem;
}
</style>
